import { Component } from '@angular/core';

@Component({
  selector: 'app-bespoken',
  templateUrl: './bespoken.component.html',
  styleUrls: ['./bespoken.component.scss']
})
export class BespokenComponent {

}
