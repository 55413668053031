<div id="about" class="about-area ptb-110">

    <div class="container pb-100">

        <div class="row">
            <div class="col-lg-12 col-10">
                <div class="search-container search-mobile">
                    <form class="search-bar" action="/search" method="GET">
                        <input type="text" name="q" placeholder="Search...">
                        <button type="submit"><i class="fas fa-search search-icon"></i></button>
                    </form>
                </div>
            </div>
            <div class="col-lg-12 col-2">
                <div class="menu-icon" (click)="toggleClass()">
                    <!--<i class="fa fa-ellipsis-v "></i>-->
                    <img class="filterimg" src="../../../assets/vbd/filter.png" alt="filter" />

                </div>
            </div>
            <div class="card" [ngClass]="{'show': classApplied}">
                <div class="row">
                    <div class="col-6">
                        <h5 *ngFor="let heading of headings" class="cursor_pointer" [ngClass]="{ 'highlight': isHeadingHighlighted(heading) }" (click)="toggleHighlight(heading)">{{ heading }}</h5>
                    </div>

                    <div class="col-6" *ngIf="classApplied" style="border-left: 3px solid lightgray;">
                        <div *ngIf="isHeadingHighlighted('Price Range')">
                            <!--<h5>Price Range</h5>-->
                            <div class="mb-30 filter-options">
                                <div>
                                    <div id="slider-range">
                                        <form>
                                            <div class="form-group">
                                                <input type="range" class="form-range" id="priceRange" max="100000" step="1000">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 theme-clr xs2-font d-flex justify-content-between">
                                <span id="slider-range-value1">&#8377; 15,000</span>
                                <span id="slider-range-value2">&#8377; 1,00,000</span>
                            </div>
                        </div>
                        <div *ngIf="isHeadingHighlighted('Button Strip')">

                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="buttonStripOptions" id="yesRadio">
                                        <label class="form-check-label" for="yesRadio">Yes</label>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="buttonStripOptions" id="noRadio">
                                        <label class="form-check-label" for="noRadio">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Loop through checkboxes for the highlighted heading -->
                        <div class="form-check" *ngFor="let checkbox of checkboxes[highlightedHeading]">
                            <input class="form-check-input" type="checkbox" [id]="'checkbox' + checkbox.id">
                            <label class="form-check-label" [for]="'checkbox' + checkbox.id">{{ checkbox.label }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-3 mt-3 mobile" style="border:1px solid lightgray">
                <div class="row borderr">
                    <h5>Price Range</h5>
                    <div class="mb-30 filter-options">
                        <div>
                            <div id="slider-range">
                                <form>
                                    <div class="form-group">
                                        <input type="range" class="form-range" id="priceRange" max="100000" step="1000">
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 theme-clr xs2-font d-flex justify-content-between">
                        <span id="slider-range-value1">&#8377; 6,000</span>
                        <span id="slider-range-value2">&#8377; 1,00,000</span>
                    </div>

                </div>

                <div class="row borderr">

                    <h5>Shirt Cut Type</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckIndeterminate1">
                        <label class="form-check-label" for="flexCheckIndeterminate1">Side Cut</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckIndeterminate2">
                        <label class="form-check-label" for="flexCheckIndeterminate2">Light Round Collar</label>
                    </div>
                </div>

                <div class="row borderr">
                    <h5>Collar</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate1">
                        <label class="form-check-label" for="flexCheckIndeterminate1">Chineese Collar</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate2">
                        <label class="form-check-label" for="flexCheckIndeterminate2">Regular Collar</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate3">
                        <label class="form-check-label" for="flexCheckIndeterminate3">Button Down</label>
                    </div>

                </div>

                <div class="row borderr">
                    <h5> Sleeve Length</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckIndeterminate1">
                        <label class="form-check-label" for="flexCheckIndeterminate1"> Half </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckIndeterminate2">
                        <label class="form-check-label" for="flexCheckIndeterminate2">  Full</label>
                    </div>
                </div>

                <div class="row borderr">
                    <h5> Hand Cuffs</h5>


                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="regularRoundCheckbox">
                        <label class="form-check-label" for="regularRoundCheckbox">Regular Round</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="regularStraightCheckbox">
                        <label class="form-check-label" for="regularStraightCheckbox">Regular Straight</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="upDownButtonCheckbox">
                        <label class="form-check-label" for="upDownButtonCheckbox">Up Down Button</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="sideButtonCheckbox">
                        <label class="form-check-label" for="sideButtonCheckbox">Side Button</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="frenchStyleCheckbox">
                        <label class="form-check-label" for="frenchStyleCheckbox">French Style</label>
                    </div>

                </div>

                <div class="row borderr">
                    <h5>Kurta type</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="shortLengthCheckbox">
                        <label class="form-check-label" for="shortLengthCheckbox">Short Length</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="longLengthCheckbox">
                        <label class="form-check-label" for="longLengthCheckbox">Long Length</label>
                    </div>

                </div>

                <div class="row borderr">
                    <h5>Kurta cut</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="straightCheckbox">
                        <label class="form-check-label" for="straightCheckbox">Straight</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="fishCutCheckbox">
                        <label class="form-check-label" for="fishCutCheckbox">Fish Cut</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="lightRoundCheckbox">
                        <label class="form-check-label" for="lightRoundCheckbox">Light Round</label>
                    </div>

                </div>
                <div class="row borderr">
                    <h5> Button Strip</h5>
                    <div class="row">

                        <div class="col-lg-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="options" id="yesRadio">
                                <label class="form-check-label" for="yesRadio">Yes</label>
                            </div>

                        </div>
                        <div class="col-lg-2">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="options" id="noRadio">
                                <label class="form-check-label" for="noRadio">No</label>
                            </div>
                        </div>


                    </div>

                </div>

                <div class="row borderr">
                    <h5> Linen Lea Count</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox60">
                        <label class="form-check-label" for="checkbox60">60</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox70">
                        <label class="form-check-label" for="checkbox70">70</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox80">
                        <label class="form-check-label" for="checkbox80">80</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox100">
                        <label class="form-check-label" for="checkbox100">100</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox120">
                        <label class="form-check-label" for="checkbox120">120</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox150">
                        <label class="form-check-label" for="checkbox150">150</label>
                    </div>
                </div>

                <div class="row">
                    <h5>Cotton Ply Count</h5>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox100-2">
                        <label class="form-check-label" for="checkbox100-2">100/2</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox200-2">
                        <label class="form-check-label" for="checkbox200-2">200/2</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox140-2">
                        <label class="form-check-label" for="checkbox140-2">140/2</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox180-2">
                        <label class="form-check-label" for="checkbox180-2">180/2</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox200-2">
                        <label class="form-check-label" for="checkbox200-2">200/2</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox240-2">
                        <label class="form-check-label" for="checkbox240-2">240/2</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox300-2">
                        <label class="form-check-label" for="checkbox300-2">300/2</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox330-3">
                        <label class="form-check-label" for="checkbox330-3">330/3</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkbox330-4">
                        <label class="form-check-label" for="checkbox330-4">330/4</label>
                    </div>
                </div>


            </div>

            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (3).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (6).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 1</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (8).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 2</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (5).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 3</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                  
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (9).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 11</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (10).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 12</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd (13).jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 13</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="image-container">
                            <img src="../../assets/vbd/vbd.jpeg" alt="Whites Galleria">
                            <div class="rating">5★ | 24.5k</div>
                            <div class="icons">
                                <i class="fas fa-share-alt"></i><br />
                                <i class="fas fa-heart"></i>
                            </div>
                        </div>
                        <h5>Product 14</h5>
                        <p>
                            " Luxure Whites" is a premier destination for luxury fashion enthusiasts seeking high-quality.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

