
<footer class="footer-area">

    <div class="container">

        <div class="row">


            <div class="col-lg-4">
                <h2  class="mt-n3">Vbd Retail</h2>
                <div>
                     VBD Retail offers a range of luxury ready-to-wear and bespoke collections made from 100% fine linen and cotton. Experience the finest materials and craftsmanship with every piece. 
                </div>
            </div>

            <div class="col-lg-4">
                 <h3>Our Links</h3> 
                        <p><a href="/">Home</a></p>
                        <p><a href="/">About Us</a></p>
                        <!--<p><a href="/">Products</a></p>-->
                        <p><a href="/">Whites Galleria</a></p>
                        <p><a href="/">Luxure Whites</a></p>
                        <p><a href="/">Contact Now</a></p>
                        <p><a href="/">Booknow</a></p>
            </div>

            <div class="col-lg-4  ">
                <a href="/ContactUs" class="">
                    <h3>Contact Us</h3>
                </a>

                <address>
                    <div style="line-height:2">
                        vani nagar,malkajgiri,
                        Hyderabad,
                        Telangana, 500047.
                    </div>
                     
                    <div style="line-height:2"><a href="tel:+919966097666" target="_blank"><i class="fa fa-phone"></i>&nbsp;+91 9966097666</a></div>
                    <div style="line-height:2">
                        <a href="mailto:info@vbdretail.com" target="_blank">
                            <i class="fa fa-envelope"></i>&nbsp;
                            info@vbdretail.com
                        </a>
                    </div>
                </address>



                <ul class="social_links">
                    <li><a target="_blank" href="https://www.facebook.com/"><i class="fab fa-facebook-f " ></i></a></li>
                    <li><a target="_blank" href="https://twitter.com/"><i class="fab fa-twitter"  ></i></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/"><i class="fab fa-instagram "  ></i></a></li>
                    <li><a target="_blank" href="https://in.linkedin.com/"><i class="fab fa-linkedin-in "  ></i></a></li>
                </ul>




            </div>


        </div>


        <hr />
        <div class="row">
            <div class="col-lg-6 ">
                <div class="rights">
                    <i class="far fa-copyright" style="color:white;"></i>
                    All rights are reserved by Vbd Retail
                </div>
            </div>

            <div class="col-lg-6 gagri">
                <div class="powered ">
                    Powered by <a href="https://gagri.net/" target="_blank">
                        <img class="Gglogo" src="../../../assets/vbd/gagrilogo.webp" alt="Gagri"   />
                    </a>
                </div>
            </div>
        </div>




    </div>






</footer>













<!--<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>X</span>avro</a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>© Xavro is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
    </div>
</footer>-->
<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>
