import { Component } from '@angular/core';

@Component({
  selector: 'app-whites-galleria',
  templateUrl: './whites-galleria.component.html',
  styleUrls: ['./whites-galleria.component.scss']
})
export class WhitesGalleriaComponent {
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }


    // Array of headings
    headings: string[] = [
        'Price Range',
        'Shirt Cut Type',
        'Collar',
        'Sleeve Length',
        'Hand Cuffs',
        'Kurta type',
        'Kurta cut',
        'Button Strip',
        'Linen Lea Count',
        'Cotton Ply Count',
    ];

    // Object to store checkboxes for each heading
    checkboxes: { [heading: string]: { id: number, label: string }[] } = {
        'Shirt Cut Type': [
            { id: 1, label: 'Side Cut' },
            { id: 2, label: 'Light Round Collar' },
        ],
        'Collar': [
            { id: 3, label: 'Chineese Collar' },
            { id: 4, label: 'Regular Collar' },
            { id: 5, label: 'Button Down' },
        ],
        'Sleeve Length': [
            { id: 6, label: 'Half' },
            { id: 7, label: '  Full' },
        ],
        'Hand Cuffs': [
            { id: 8, label: 'Regular Round' },
            { id: 9, label: 'Regular Straight' },
            { id: 10, label: 'Up Down Button' },
            { id: 11, label: 'Side Button' },
            { id: 12, label: 'French Style' },
        ],
        'Kurta type': [
            { id: 13, label: 'Short Length' },
            { id: 14, label: 'Long Length' },
        ],
        'Kurta cut': [
            { id: 15, label: 'Straight' },
            { id: 16, label: 'Fish Cut' },
            { id: 17, label: 'Light Round' },
        ],

        'Linen Lea Count': [
            { id: 18, label: '60' },
            { id: 19, label: '70' },
            { id: 20, label: '80' },
            { id: 21, label: '100' },
        ],
        'Cotton Ply Count': [
            { id: 3, label: '100/2' },
            { id: 4, label: '200/2' },
            { id: 4, label: '140/2' },
            { id: 4, label: '180/2' },
            { id: 4, label: '240/2' },
            { id: 4, label: '280/2' },
            { id: 4, label: '300/2' },
        ],
        // Add checkboxes for other headings similarly
    };

    // Variable to keep track of the highlighted heading
    highlightedHeading: string | null = null;

    // Method to toggle highlighting for a specific heading
    toggleHighlight(heading: string) {
        if (this.highlightedHeading === heading) {
            // If the clicked heading is already highlighted, remove highlighting
            this.highlightedHeading = null;
            this.classApplied = false; // Hide checkboxes when heading is unhighlighted
        } else {
            // Otherwise, highlight the clicked heading and show checkboxes
            this.highlightedHeading = heading;
            this.classApplied = true;
        }
    }

    // Method to check if a specific heading is highlighted
    isHeadingHighlighted(heading: string): boolean {
        return this.highlightedHeading === heading;
    }
}
