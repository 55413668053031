

<nav [class.active]="classApplied"
     [ngClass]="{'sticky': isSticky}">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-xs-1   none" (click)="toggleClass()">
                <i class="fa fa-bars bars cursor_pointer"></i>
            </div>
            <div class="card1" [ngClass]="{'show': classApplied}">
                <ul class="navbar-nav ml-auto for-responsive">
                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('/')">Products </span></li>
                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('Contactus')">Contact Us </span></li>
                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('/')">Terms & conditions </span></li>
                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('/')">Privacy Policy </span></li>
                </ul>
            </div>
            <div class="col-lg-12  col-xs-11">
                <a class="navbar-brand mx-auto" routerLink="/"><h1 style="">Vbd Retail</h1></a>

            </div>

            <div class="col-lg-6 col-6 navs"style="cursor:pointer" routerLink="WhitesGalleria">
                <div class="txtheadding">
                    <h3 >
                        Whites Galleria
                    </h3>
                </div>
                <div class="row">
                    <div class="col-lg-4 display"></div>
                    <div class="col-lg-5 subheadding">
                        <small>Ready to wear</small>
                        <p>100% Fine Linen </p>
                        <p>100% Fine Cotton</p>
                    </div>
                    <div class="col-lg-3 display"></div>
                </div>
            </div>
            <div class="col-lg-6 col-6 navs" style="cursor:pointer" routerLink="LuxuryWhites">
                <div class="txtheadding">
                    <h3 >
                        Luxure Whites
                    </h3>

                </div>
                <div class="row">
                    <div class="col-lg-4 display"></div>
                    <div class="col-lg-5 subheadding">
                        <small>Bespoke</small>
                        <p>100% Fine Linen </p>
                        <p>100% Fine Cotton</p>
                    </div>
                    <div class="col-lg-3 display"></div>
                </div>
            </div>
        </div>
    </div>




</nav>


<script>// Function to toggle class
  function toggleClass() {
    var card = document.querySelector('.card1');
    card.classList.toggle('show');
  }

  // Function to handle click event
  function onClick(item) {
    // Remove highlight class from all items
    var items = document.querySelectorAll('.nav-item');
    items.forEach(function(item) {
      item.classList.remove('highlight');
    });

    // Add highlight class to the clicked item
    event.currentTarget.classList.add('highlight');
  }</script>



<!--<div class="container-fluid">
       <a class="navbar-brand" routerLink="/"><span>vbd</span>REtail</a>
       <button class="navbar-toggler" type="button" (click)="toggleClass()">
           <span class="burger-menu">
               <span class="top-bar"></span>
               <span class="middle-bar"></span>
               <span class="bottom-bar"></span>
           </span>
       </button>
       <div class="collapse navbar-collapse" id="navbarSupportedContent">
           <ul class="navbar-nav ml-auto">
               <li class="nav-item"><span class="nav-link" routerLink="/home">Home</span></li>
               <li class="nav-item"><span class="nav-link" routerLink="/Aboutus">About</span></li>
               <li class="nav-item">
                   <div class="dropdown">
                       <span class="nav-link" routerLink="/">Products <i class="fa fa-caret-down "></i></span>
                       <div class="dropdown-content">
                           <a routerLink="/Products">Whites Galleria</a><br />
                           <a routerLink="/Products">Luxury Whites </a>
                       </div>
                   </div>
               </li>
               <li class="nav-item"><span class="nav-link" routerLink="/Bespoken">Bespoken</span></li>
               <li class="nav-item"><span class="nav-link" routerLink="/Perfumes">Perfumes</span></li>
               <li class="nav-item"><span class="nav-link" routerLink="/Contactus">Contact</span></li>
               <li class="nav-item"><span class="nav-link" routerLink="/Booknow"><span class="btn btn-primary">Booknow</span></span></li>

           </ul>
           <ul class="navbar-nav ml-auto icons">
               <li class="nav-item"><a class="nav-link" href="/profile"><i class="fas fa-user"></i></a></li>
               <li class="nav-item"><a class="nav-link" href="/cart"><i class="fas fa-shopping-cart"></i></a></li>
               <li class="nav-item"><a class="nav-link" href="/wishlist"><i class="fas fa-heart"></i></a></li>
           </ul>
           <ul class="navbar-nav ml-auto for-responsive">
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Team</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Services</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Work</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Pricing</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">Blog</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Contact</span></li>
           </ul>


       </div>

   </div>-->
<!--<li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>-->
